.main-container {
  width: 100%;
  background-color: #080808;
  color: white;
}

.about-hero {
  width: 100%;
  background: linear-gradient(
      183deg,
      rgba(65, 105, 225, 0.15) -38.62%,
      rgba(65, 105, 225, 0) 97.56%
    ),
    center / cover no-repeat url(/public/images/hero_bg.webp);
}

.about-hero-wrapper {
  padding: 100px 86px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about-text-col {
  flex-basis: 50%;
  margin-right: auto;
}
.about-img-col {
  flex-basis: 50%;
  display: grid;
  place-content: center;
}
.about-img-col div {
  max-width: 500px;
  height: 500px;
}
.about-img-col div img {
  width: 100%;
  width: 100%;
  display: block;
}

.about-text-col h1 {
  font-size: 72px;
  font-weight: 600px;
  gap: 20px;
}
.about-text-col p {
  font-size: 20px;
  font-weight: 500px;
}

.mission-vision {
  display: flex;
  padding: 70px 120px;
  gap: 60px;
}
.vision,
.mission {
  display: flex;
  gap: 31px;
}
.vision img,
.mission img {
  width: 80px;
  height: 80px;
}
.vision-section {
  max-width: 410px;
}

.represent-wrapper {
  background-color: #111111;
  padding: 40px 86px;
  margin-top: 100px;
}

.represent-main {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1124px;
  margin-inline: auto;
  gap: 60px;
}

.represent h1 {
  font-weight: 600;
  font-size: 45px;
}

.represent {
  flex: 1;
  color: #d4d4d4;
  font-size: 12px;
}

.person {
  flex: 1;
  align-self: stretch;
  position: relative;
}
.person img {
  height: 120%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 8px;
}
.core-value-wrapper {
  width: 100%;
  padding: 0 130px;
}
.business-value-wrapper{  
  width: 100%;
  padding: 0 87px;
}

.cores-values,
.business-values {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  align-items: stretch;
  gap: 30px;
}

.core h1 {
  font-weight: 400;
  font-size: 60px;
  text-align: center;
}

.core p {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  margin-top: 2rem;
  margin-bottom: 7rem;
  color: #d4d4d4;
}

.business h1 {
  font-weight: 400;
  font-size: 60px;
  margin-top:  4rem;
  text-align: center;
}

.business p {
  font-weight: 500;
  font-size: 18px;
  color: #d4d4d4;
  margin-bottom: 70px;
  text-align: center;
}
.expand-on-hover-btn {
  color: #fff;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.expand-on-hover-btn:hover {
  transform: scale(1.1);
}

.button-text {
  font-size: 14px;
  font-weight: bold;
  font-family: "Noto Sans", sans-serif;
  transition: color 0.3s ease;
}

.expand-on-hover-btn:hover .button-text {
  color: #ffc107;
}
@media only screen and (max-width: 1120px) {
  .core-value-wrapper {
    padding: 0 80px;
  }
}
@media only screen and (max-width: 970px) {
  .about-hero-wrapper {
    padding: 100px 37px 50px;
  }

  .about-text-col h1 {
    font-size: 60px;
  }
  .about-text-col p {
    font-size: 16px;
  }
  .mission-vision {
    padding: 25px 37px;
  }
  .vision img,
  .mission img {
    width: 60px;
    height: 60px;
  }
  .represent-wrapper {
    padding: 40px 37px;
    margin-top: 120px;
  }
  .represent h1 {
    font-size: 36px;
  }
  .core-value-wrapper,.business-value-wrapper {
    padding: 0 37px;
  }
  .cores-values,
  .business-values {
    gap: 20px;
  }
  .represent-wrapper {
    background-color: #111111;
    padding: 120px 20px 50px 30px;
    margin-top: 100px;
  }
  .mission-vision {
    display: flex;
    padding: 50px 20px 0px 35px;
    gap: 60px;
  }
  .person img {
    height: 125%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 8px;
  }
  .core h1,
  .business h1 {
    font-size: 48px;
    margin-top: 60px;
  }
  .core p,
  .business p {
    margin: 20px 0 48px;
  }
 
}
@media only screen and (max-width: 850px) {
  .about-hero-wrapper {
    padding: 0 37px 0;
  }
  .about-img-col {
    padding-top: 150px;
  }
  .about-hero {
    height: 100%;
  }
  .mission-vision {
    display: flex;
    padding: 50px 20px 0px 35px;
    gap: 60px;
  }
  .vision-section {
    width: 100%;
  }
  .cores-values,
  .business-values {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 750px) {
  .mission-vision {
    flex-direction: column;
  }

  .represent-wrapper {
    margin-top: 65px;
  }
  .person {
    height: 450px !important;
    align-self: center;
  }
  .person img {
    height: 100%;
    position: static;
  }
}
@media only screen and (max-width: 633px) {
  .about-hero-wrapper {
    padding: 0 15px 0;
  }
  .about-img-col {
    padding-top: 170px;
  }
  .about-text-col h1 {
    font-size: 36px;
  }
  .about-hero {
    height: 75vh;
  }
  .mission-vision {
    padding: 25px 15px;
    gap: 24px;
    flex-direction: column;
  }

  .vision img,
  .mission img {
    width: 40px;
    height: 40px;
  }

  .represent-wrapper {
    padding: 40px 15px;
  }
  .represent h1 {
    font-size: 30px;
  }

  .core-value-wrapper, .business-value-wrapper{  
    padding: 0 15px;
  }
}
@media only screen and (max-width: 530px) {
  .about-hero {
    min-height: 90vh;
  }
  .about-hero-wrapper {
    flex-direction: column;
    padding-top: 150px;
  }
  .about-img-col {
    padding-top: 0;
  }
  .vision-section p {
    font-size: 14px;
    width: 100%;
  }
  .represent-main {
    flex-direction: column;
  }
  .core h1,
  .business h1 {
    font-size: 30px;
  }

  .core p,
  .business p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 460px) {
  .mission-vision {
    display: flex;
    padding: 50px 20px 0px 20px;
    gap: 60px;
  }
  .about-hero {
    min-height: 90vh;
  }
  .core h1,
  .business h1 {
    font-size: 25px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  .core p,
  .core h3{
    font-size: 16px;
  }
  .business p{
    font-size: 16px;
    padding-top: 0px;
    margin-top: 0px;
  }
  .cores-values,
  .business-values {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media only screen and (max-width: 360px) {
  .about-hero {
    min-height: 90vh;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating-globe {
  animation: rotate 30s linear infinite; 
}