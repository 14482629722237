.homepage {
  max-width: 100%;
  height: auto;
  background: #000;
}

/* hero section styles */
.homepage_hero {
  max-width: 100%;
  max-height: 90vh;
  min-height: 80vh;
  padding: 250px 60px 140px;
  text-align: center;
  place-content: center;
  background: linear-gradient(
      183deg,
      rgba(65, 105, 225, 0.15) -38.62%,
      rgba(65, 105, 225, 0) 97.56%
    ),
    center / cover no-repeat url(/public/images/hero_bg.webp);
}
.Typewriter {
  height: 290px;
}

span.Typewriter__cursor {
  display: none;
}

.homepage_hero h1 {
  font-size: 96px;
}

.homepage_hero p {
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0.15;
}
.brand {
  margin-top: -150px;
}
/* home page main (with vertical slider component) styles */
.homepage #main-content {
  padding: 150px 0;
  height: 850px;
}

.text_col {
  width: 50%;
  height: 100%;
}
.text_col h2 {
  font-size: 48px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.24px;
  width: 80%;
  padding-bottom: 24px;
}
.text_col p {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  padding-bottom: 26px;
}

.vertical_slide {
  width: 50%;
  height: 100%;
}

/* middle section styles */
.middle_section {
  padding: 90px 0;
  width: 100%;
  background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(199, 92, 15, 0.116) 0%,
      rgba(217, 217, 217, 0) 100%
    ),
    center / cover no-repeat url(/public/images/grid_background.png);
}

.pointer {
  cursor: pointer;
}
.middle_section .container {
  gap: 50px;
}
.middle_section .text_col {
  text-align: right;
  /* width: 50%; */
  flex: 1;
}
.middle_section .text_col h2 {
  margin-left: auto;
  width: 100%;
}
.middle_section .text_col p {
  margin-left: auto;
  line-height: 30px;
  letter-spacing: 0.09px;
}

.video_wrapper {
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
  text-align: center;
}

.video_wrapper div {
  max-width: 500px;
  height: 300px;
  border-radius: 8px;
  overflow: hidden;
  margin: 0 auto;
}
.video_wrapper video {
  width: 100%;
  height: 100%;
}

/* About us section */

.homepage_about {
  background: linear-gradient(
      to top,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    center / cover no-repeat url(/public/images/about_bg.png);
  padding-top: 152px;
}
.homepage_about h2 {
  font-size: 60px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 80px;
}
.homepage_about h5 {
  font-size: 30px;
  font-weight: 600;
  padding-bottom: 24px;
  letter-spacing: 0.3px;
}
.text_col .first_text {
  padding-bottom: 48px;
}

.text_col .second_text {
  padding-bottom: 24px;
}
.homepage_about > div {
  padding-bottom: 152px;
}

/* testimonial padding */
.testimonial_home {
  padding: 100px 0;
  height: auto;
}

.testimonial_home h3 {
  font-size: 60px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 7px;
}
.testimonial_home .testimonial_txt{
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 48px;
  text-align: center;
}

@media only screen and (max-width: 2480px) {

  .hero_gap{
    padding-top: 80px;
  }
  .inner_padding{
    padding-left: 60px;
    padding-right: 60px;
    font-size: 12px;
  }
  .Typewriter {
    height: 220px;
    width: 920px;
    font-size: 40px;
  }
}

@media only screen and (max-width: 1300px) {

  .hero_gap{
    padding-top: 60px;
  }
  .inner_padding{
    padding-left: 60px;
    padding-right: 60px;
    font-size: 12px;
  }
  .Typewriter {
    height: 240px;
  }
}

@media only screen and (max-width: 1100px) {
  .homepage_about .container {
    gap: 30px;
  }
  .hero_gap{
    padding-top: 20px;
  }

  .Typewriter {
    height: 240px;
    width: 980px;
    text-align: center;
  }

  .homepage #main-content {
    margin-bottom: 50px;
  }

  .middle_section .text_col {
    width: 70%;
  }
}
@media only screen and (max-width: 1078px) {
  .text_col h2 {
    width: 100%;
  }

  #main-content .text_col {
    padding-right: 50px;
  }

  #main-content .text_col p {
    width: 100%;
  }

  .vertical_slide {
    height: 500px;
  }

  .homepage_hero h1 {
    font-size: 84px;
  }

  .homepage_hero p {
    font-size: 22px;
  }
}

/* for tabs */

@media only screen and (max-width: 970px) {
  /* Vertical slider styling */
  .homepage_hero h1 {
    font-size: 60px;
  }

  .hero_gap{
    padding-top: 0px;
    padding-bottom: 20px;
  }

  .Typewriter {
    height: 200px;
    width: 680px;
  }

  #main-content .container {
    gap: 50px;
  }

  #main-content .text_col {
    padding-right: 0px;
  }
  .homepage #main-content {
    padding: 100px 0 50px;
    min-height: 1000px;
  }

  .text_col {
    width: 100%;
    text-align: center;
  }
  .vertical_slide {
    width: 100%;
    height: 450px;
  }
  .text_col p {
    font-size: 16px;
    font-weight: 400px;
  }
  .middle_section .text_col h2 {
    width: 70%;
  }
  .text_col h2 {
    width: 70%;
    margin: 0 auto;
    font-size: 36px;
  }

  .testimonial_home h3 {
    font-size: 36px;
    padding-bottom: 10px;
  }
  .testimonial_home .testimonial_txt {
    font-size: 16px;
    padding-bottom: 30px;
  }

  /* style for section with video */
  .middle_section .flex_wrapper {
    flex-direction: column-reverse;
    gap: 50px;
  }

  .middle_section .text_col {
    text-align: center;
    width: 100%;
  }

  .middle_section .text_col p {
    width: 100%;
  }

  /* homepage about section */
  .homepage_about {
    padding-top: 80px;
  }

  .homepage_about h2 {
    font-size: 36px;
    padding-bottom: 50px;
  }
  .homepage_about .container .text_col {
    text-align: left;
  }

  .homepage_about .container {
    gap: 50px;
    flex-direction: column-reverse;
  }

  .homepage_about .image_col {
    width: 100%;
  }
  .homepage_about .image_col img {
    width: 100%;
  }

  .homepage h2,
  .homepage h3,
  .homepage h4 {
    font-size: 36px;
  }
}
@media only screen and (max-width: 700px) {
  .homepage #main-content {
    min-height: 1200px;
  }
}
@media only screen and (max-width: 650px) {
  .homepage_hero {
    height: 70vh;
  }
  .homepage_hero p {
    font-size: 20px;
    width: 85%;
    margin: 0 auto;
  }
  .hero_gap{
    padding-bottom: 20px;
  }
  .Typewriter {
    height: 180px;
    width: 540px;
    text-align: center;
  }
  .homepage #main-content {
    min-height: 950px;
    margin-bottom: 50px;
  }
  .hero_gap{
    padding-top: 180px;
  }
}
@media only screen and (max-width: 560px) {
  .text_col h2 {
    width: 100%;
    font-size: 24px;
  }

  .hero_gap{
    padding-top: 50px;
  }
  /* THE VERTICAL SLIDER SECTION */
  .homepage #main-content {
    padding: 100px 0 20;
  }

  /* video area */
  .middle_section {
    min-height: 820px;
    padding: 50px 0;
  }

  .video_wrapper div {
    width: 100%;
  }
  

  .homepage_about > div {
    padding-bottom: 50px;
  }

  /* testimonial padding */
  .testimonial_home {
    padding: 30px 0;
  }
  .homepage h2,
  .homepage h3,
  .homepage h4 {
    font-size: 24px;
  }

  .homepage_about h5 {
    font-size: 24px;
  }
  .homepage #main-content {
    min-height: 800px;
  }
  .middle_section {
    padding: 30px 0 0;
  }
  .middle_section .text_col h2 {
    width: 100%;
  }
}

@media only screen and (max-width: 460px) {
  .homepage_hero h1 {
    font-size: 36px;
  }
  .hero_gap{
    padding-bottom: 20px;
  }
  .Typewriter {
    height: 80px;
    width: 450px;
    text-align: center;
  }
  .homepage_hero p {
    font-size: 18px;
    width: 100%;
  }

  .homepage_hero {
    padding: 100px 20px 0;
    min-height: 70vh !important;
  }
  .brand {
    margin-top: -150px;
  }
  .testimonial_home h3 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 425px) {
  .middle_section {
    padding: 10px 0;
  }
  .homepage_about h5 {
    font-size: 20px;
  }
  .hero_gap{
    padding-bottom: 20px;
  }
  .Typewriter {
    height: 80px;
    width: 350px;
    text-align: center;
  }
}
@media only screen and (max-width: 390px) {
  .Typewriter {
    height: 100px;
    width: 350px;
    text-align: center;
  }
  .Typewriter h3{
    font-size: 18px;
  }

  .vertical_slide {
    height: 500px;
  }
  .middle_section {
    min-height: 880px;
    
  }
}
