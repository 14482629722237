@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700&display=swap');

:root{
  --primary: #7169E1;
  --btn-gradient: linear-gradient(177deg, #4169E1 18.75%, #FFA500 231%);
  --transition-speed : all 0.5s ease-in-out;
  --secondary-color : #FFA500;
  

}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  width: 100%;
  height: 100%;
  font-family: 'Noto Sans', sans-serif;
}

h1{
  font-weight: 700;
}

h2,
h3, 
h4,
h5{
  font-weight: 600;

}

.flex_wrapper{
  display: flex;
}

.flex_apart{
  justify-content: space-between;
  align-items: center;
}

.flex_center{
  justify-content: center;
  align-items: center;
}

.flex_start{
  align-items: flex-start;
  justify-content: space-between;
}
.flex_stretch{
  align-items: stretch;
  justify-content: space-between;
}

.grid_wrapper{
  display: grid;
}

.grid_col3{
  grid-template-columns: repeat(3, 1fr);
  gap: 0.8rem;
}

a{
  text-decoration: none;
}

.btn{
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  color: #fff;
  background: var(--btn-gradient);
  cursor: pointer;
  transition: var(--transition-speed);
}

.btn_lg{
  width: 258px;
  padding: 12px 24px;
  display: inline-block;
  margin: 30px auto;
  text-align: center;
}

.btn:hover{
  transform: scale(1.05);
  background: var(--primary);
}
.container{
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 28px;
}
.btn_arr{
  color: var(--secondary-color);
  font-weight: 400;
  font-size: 20px;
}
.rounded_border {
  border-radius: 8px;
}

@media only screen and (max-width: 1240px){
  .container{
    padding: 0 50px;

  }
}

@media only screen and (max-width: 970px){
  .container{
    padding: 0 32px;

  }
  .flex_wrapper{
    flex-direction: column;
  }
}
@media only screen and (max-width: 560px){
  .container{
    padding: 0 15px;

  }
  .btn_arr{
    font-weight: 400;
    font-size: 14px;
  }
}
